export const isLocationWithinBounds = <T>(
  bounds: google.maps.LatLngBoundsLiteral,
  item: T,
  getLat: (item: T) => number,
  getLng: (item: T) => number,
): boolean => {
  const lat = getLat(item);
  const lng = getLng(item);

  // Check if the location is within bounds
  const withinBounds =
    lat >= bounds.south && lat <= bounds.north && lng >= bounds.west && lng <= bounds.east;

  return withinBounds;
};

export const generateBoundsFromLatLng = (lat: number, lng: number, rangeInMi = 10) => {
  const earthRadiusInMeters = 6378137;
  const radiusInMeters = rangeInMi * 1609.34;
  const latDelta = (radiusInMeters / earthRadiusInMeters) * (180 / Math.PI);
  const lngDelta =
    (radiusInMeters / (earthRadiusInMeters * Math.cos((Math.PI * lat) / 180))) *
    (180 / Math.PI);
  const bounds = {
    north: lat + latDelta,
    south: lat - latDelta,
    east: lng + lngDelta,
    west: lng - lngDelta,
  };
  return bounds;
};
